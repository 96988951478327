import { baseQuery } from '../initializers/rtk';
import { createApi } from '@reduxjs/toolkit/query/react';
import humps from 'humps';
import { Repository } from 'src/types/github/Repository';

export const repositoriesApi = createApi({
  baseQuery,
  reducerPath: 'repositories',
  tagTypes: ['Repositories'],
  endpoints: builder => ({
    getRepositories: builder.query<Repository[], any>({
      query: ({ workspaceId }) => ({
        url: `/repositories`,
        params: humps.decamelizeKeys({ workspaceId }),
        withCredentials: true,
      }),
      providesTags: ['Repositories'],
      transformResponse: (response: Repository[]): Repository[] =>
        humps.camelizeKeys(response) as Repository[],
    }),
  }),
});

export const { useGetRepositoriesQuery } = repositoriesApi;
