/**
 * Converts a hex color string to an RGB color.
 * @param hex The hex color string (e.g., "#ffffff" or "ffffff").
 * @returns An object with the red, green, and blue components in the range [0, 255].
 */
export function hexToRgb(hex: string, alpha: number = 1): string {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // Parse the hex string
  let r = 0,
    g = 0,
    b = 0;
  // If it's a 3-character hex code
  if (hex.length === 3) {
    r = parseInt(hex[0] + hex[0], 16);
    g = parseInt(hex[1] + hex[1], 16);
    b = parseInt(hex[2] + hex[2], 16);
  }
  // If it's a 6-character hex code
  else if (hex.length === 6) {
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  }

  return `rgb(${r}, ${g}, ${b}, ${alpha})`;
}
