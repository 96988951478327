import { useParams } from 'react-router-dom';
import {
  useGetWorkspaceQuery,
  useUpdateWorkspaceMutation,
} from 'src/slice/workspaces';
import { Workspace } from 'src/types/Workspace';

export function useWorkspace() {
  const { workspaceId } = useParams();
  const { data, isLoading } = useGetWorkspaceQuery({ workspaceId });
  const [updateWorkspace] = useUpdateWorkspaceMutation({});

  function onUpdate(workspaceParams: Partial<Workspace>) {
    updateWorkspace({ workspaceId, ...workspaceParams });
  }

  return {
    workspace: data,
    isLoading,
    onUpdate,
  };
}
