import { Label } from './label';
import { PullRequest } from './PullRequest';

export interface Issue {
  id: number;
  workspaceId: number;
  number: number;
  title: string;
  body: string;
  htmlUrl: string;
  labels: Label[];
  pullRequests: PullRequest[];
  openedAt: string;
  state: IssueState;
}

enum IssueState {
  NotFixed = 'not_fixed',
  Fixing = 'fixing',
  FailedToFix = 'failed_to_fix',
  PendingApproval = 'pending_approval',
  Fixed = 'fixed',
}

export const HumanizeIssueState = {
  not_fixed: 'Not Fixed',
  fixing: 'Fixing...',
  failed_to_fix: 'Failed to fix',
  pending_approval: 'Pending approval',
  fixed: 'Fixed',
};
