import { useParams } from 'react-router-dom';
import { useLoginQuery, useLazyLogoutQuery } from '../slice/auth';

export function useAuth() {
  const { workspaceId } = useParams();
  const { data, isLoading } = useLoginQuery({ workspaceId });
  const [logout] = useLazyLogoutQuery({});

  function onLogout() {
    logout({}).then(() => window.location.reload());
  }

  return {
    user: data,
    isLoading,
    isLoggedIn: !!(data?.email && data?.id && data?.lastUsedWorkspaceId),
    logout: onLogout,
  };
}
