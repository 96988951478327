import { Menu, MenuButton, MenuList, MenuItem } from 'src/components/core/Menu';
import { useAuth } from 'src/hooks/useAuth';
import { getInitials } from 'src/utils/get-initials';

export const AccountMenu: React.FC = () => {
  const { user, logout } = useAuth();

  if (!user) return null;

  return (
    <Menu>
      <MenuButton borderRadius="lg">
        <div className="cursor-pointer flex items-center gap-x-4 px-3 py-3 text-sm font-semibold leading-6 text-gray-900">
          {user.avatar ? (
            <img
              className="inline-block h-6 w-6 rounded-full"
              src={user.avatar}
              alt=""
            />
          ) : (
            <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-500">
              <span className="text-xs font-medium leading-none text-white">
                {getInitials(user.email)}
              </span>
            </span>
          )}

          <span aria-hidden="true">{user.name || user.email}</span>
        </div>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </MenuList>
    </Menu>
  );
};
