import { Button } from '@chakra-ui/react';
import { url } from 'src/constants/backend';

export const GithubSignupButton: React.FC = () => {
  const redirectToGithub = () => {
    const clientId = 'Iv1.ab6938c92de9b15c';
    const redirectUri = `${url}/auth/github/callback`;
    const scope = 'user:email';
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;
  };

  return (
    <Button w="full" onClick={redirectToGithub}>
      Sign up with GitHub
    </Button>
  );
};
