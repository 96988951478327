import {
  Menu as ChakraMenu,
  MenuButton as ChakraMenuButton,
  MenuList as ChakraMenuList,
  MenuItem as ChakraMenuItem,
  ComponentDefaultProps,
} from '@chakra-ui/react';
import { cx } from 'src/utils/cx';

export const Menu: React.FC<ComponentDefaultProps> = ({
  children,
  ...props
}) => {
  return (
    <ChakraMenu matchWidth={true} {...props}>
      {children}
    </ChakraMenu>
  );
};

export const MenuButton: React.FC<ComponentDefaultProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <ChakraMenuButton
      className={cx('hover:bg-gray-100 ', className)}
      w="full"
      {...props}
    >
      {children}
    </ChakraMenuButton>
  );
};

export const MenuList: React.FC<ComponentDefaultProps> = ({
  children,
  ...props
}) => {
  return (
    <ChakraMenuList
      className="px-1.5 mx-2 shadow-sm border-gray-200"
      {...props}
    >
      {children}
    </ChakraMenuList>
  );
};

export const MenuItem: React.FC<ComponentDefaultProps> = ({
  children,
  ...props
}) => {
  return (
    <ChakraMenuItem
      _hover={{ background: 'bg-gray-100' }}
      className="hover:bg-gray-100 focus:bg-gray-100 text-sm rounded-md"
      {...props}
    >
      {children}
    </ChakraMenuItem>
  );
};
