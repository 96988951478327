import { ComponentDefaultProps } from '@chakra-ui/react';

export const Container: React.FC<ComponentDefaultProps> = ({ children }) => {
  return <div className="py-6 flex flex-row gap-[200px]">{children}</div>;
};

export const Title: React.FC<ComponentDefaultProps> = ({ children }) => {
  return (
    <p className="text-sm font-medium leading-6 text-gray-900">{children}</p>
  );
};

export const Description: React.FC<ComponentDefaultProps> = ({ children }) => {
  return <p className="text-sm text-gray-500">{children}</p>;
};

export const Action: React.FC<ComponentDefaultProps> = ({ children }) => {
  return <div className="flex flex-col gap-2">{children}</div>;
};
