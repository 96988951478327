import { Button, Tooltip } from '@chakra-ui/react';
import { HumanizeIssueState, Issue } from 'src/types/github/Issue';
import { cx } from 'src/utils/cx';
import { hexToRgb } from 'src/utils/hex-to-rgb';
import { Skeleton } from './skeleton';
import moment from 'moment';
import { Repository } from 'src/types/github/Repository';
import { useIssues } from 'src/hooks/useIssues';

export const List: React.FC<{
  issues: Issue[];
  isLoading: boolean;
  selectedRepository: Repository;
}> = ({ issues, isLoading, selectedRepository }) => {
  const { fixIssue } = useIssues();

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="py-3.5 pl-10 pr-3 text-left text-sm font-semibold text-gray-900"
          >
            Name
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Labels
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            Opened
          </th>
          <th
            scope="col"
            className="px-3 pr-8 py-3.5 text-left text-sm font-semibold text-gray-900"
          >
            State
          </th>
          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0"></th>
          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0"></th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            {issues.map(issue => (
              <tr key={issue.id}>
                <Tooltip label={issue.title} hasArrow>
                  <td className="whitespace-nowrap py-4 pl-10 pr-3 text-sm font-medium text-gray-900 max-w-[350px] truncate">
                    {issue.title}
                  </td>
                </Tooltip>

                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div className="flex gap-1 items-center">
                    {issue.labels.map(label => {
                      return (
                        <span
                          style={{
                            backgroundColor: hexToRgb(label.color, 0.1),
                            color: hexToRgb(label.color, 1),
                          }}
                          className={cx(
                            'inline-flex items-center rounded-md px-2 py-1 text-xs font-semibold',
                          )}
                        >
                          {label.name}
                        </span>
                      );
                    })}
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-[100px]">
                  <p className="text-sm">{moment(issue.openedAt).fromNow()}</p>
                </td>
                <td className="max-w-[100px] whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <p className="text-sm">{HumanizeIssueState[issue.state]}</p>
                </td>
                <td className="pr-8">
                  <div className="flex gap-4 items-center justify-end">
                    {issue.pullRequests.length === 0 ? (
                      <Button w="80px" onClick={() => fixIssue(issue.id)}>
                        Fix
                      </Button>
                    ) : (
                      <Button
                        as="a"
                        href={issue.pullRequests[0].githubHtmlUrl}
                        target="_blank"
                      >
                        View PR
                      </Button>
                    )}
                    <a
                      className="text-sm font-semibold"
                      href={`https://github.com/${selectedRepository.fullName}/issues/${issue.number}`}
                      target="_blank"
                    >
                      View issue
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </>
        )}
      </tbody>
    </table>
  );
};
