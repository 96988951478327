import {
  Cog6ToothIcon,
  HomeIcon,
  QueueListIcon,
} from '@heroicons/react/24/outline';
import { cx } from 'src/utils/cx';
import { AccountMenu } from './account-menu';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useWorkspace } from 'src/hooks/useWorkspace';
import { Avatar, Skeleton } from '@chakra-ui/react';

export const SideNav: React.FC = () => {
  const { workspaceId } = useParams();
  const { workspace, isLoading } = useWorkspace();

  const navigate = useNavigate();
  const location = useLocation();

  const navigation = [
    { name: 'Setup', path: `/${workspaceId}/setup`, icon: Cog6ToothIcon },
    { name: 'Issues', path: `/${workspaceId}/issues`, icon: QueueListIcon },
  ].map(item => ({
    ...item,
    current: location.pathname === item.path,
  }));

  return (
    <div className="absolute inset-y-0 z-50 flex w-full flex-col">
      <div className="flex grow flex-col gap-y-5 overflow-y-auto px-4">
        <nav className="flex flex-1 flex-col mt-5">
          <ul role="list" className="flex flex-1 flex-col gap-y-7 pb-2">
            <li>
              {isLoading ? (
                <Skeleton className="h-24 w-full" />
              ) : (
                <div className="flex w-full items-center gap-x-3">
                  <Avatar
                    name={workspace.name}
                    size="xs"
                    borderRadius="lg"
                    bg="black"
                  />
                  <p className="leading-3 font-semibold text-lg">
                    {workspace.name}
                  </p>
                </div>
              )}
            </li>
            <li>
              <ul role="list" className="space-y-1">
                {navigation.map(item => (
                  <li
                    key={item.name}
                    onClick={() => navigate(item.path)}
                    className={cx(
                      'group cursor-pointer flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium items-center',
                      item.current
                        ? 'bg-gray-100 text-black font-bold'
                        : 'text-gray-500 hover:text-black hover:bg-gray-100',
                    )}
                  >
                    <item.icon
                      className={cx(
                        item.current ? 'black' : 'text-gray-400',
                        'h-5 w-5 shrink-0',
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </li>
                ))}
              </ul>
            </li>
            <li className="w-full mt-auto">
              <AccountMenu />
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
