import { useEffect, useState } from 'react';
import { List } from 'src/components/issues/list';
import * as Page from 'src/components/layout/page';
import { useIssues } from 'src/hooks/useIssues';
import { useRepositories } from 'src/hooks/useRepositories';
import { Repository } from 'src/types/github/Repository';
import { RepositoryMenu } from 'src/components/issues/repository-menu';

export const Issues: React.FC = () => {
  const { issues, isLoadingIssues, fetchIssues } = useIssues();
  const { repositories, setSearchRepositoriesQuery, filteredRepositories } =
    useRepositories();

  const [selectedRepository, setSelectedRepository] =
    useState<Repository | null>(null);

  useEffect(() => {
    if (selectedRepository) fetchIssues(selectedRepository.fullName);
  }, [selectedRepository]);

  useEffect(() => {
    if (!selectedRepository && repositories?.length > 0) {
      setSelectedRepository(repositories[0]);
    }
  }, [repositories]);

  return (
    <Page.Container>
      <Page.Header>
        <Page.Title>Issues</Page.Title>
        <Page.Actions>
          <RepositoryMenu
            selectedRepository={selectedRepository}
            setSelectedRepository={setSelectedRepository}
            filteredRepositories={filteredRepositories}
            setSearchRepositoriesQuery={setSearchRepositoriesQuery}
          />
        </Page.Actions>
      </Page.Header>
      <Page.Divider />
      <Page.Body>
        <List
          issues={issues}
          isLoading={isLoadingIssues}
          selectedRepository={selectedRepository}
        />
      </Page.Body>
    </Page.Container>
  );
};
