import { useEffect, useState } from 'react';
import { Button, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useWorkspace } from 'src/hooks/useWorkspace';

export const OpenAiApiKeyInput: React.FC = () => {
  const { workspace, onUpdate } = useWorkspace();
  const [apiKey, setApiKey] = useState<string | undefined>();

  function handleClick() {
    onUpdate({ ...workspace, apiKey });
  }

  useEffect(() => {
    if (workspace) setApiKey(workspace.apiKey);
  }, [workspace]);

  return (
    <InputGroup size="md">
      <Input
        borderRadius="md"
        focusBorderColor="black"
        size="sm"
        pr="5rem"
        pl={2}
        placeholder="OpenAI API key"
        value={apiKey}
        onChange={e => setApiKey(e.currentTarget.value)}
      />
      <InputRightElement width="4.5rem">
        <Button h="1.50rem" mr={1} mb={2} size="sm" onClick={handleClick}>
          Update
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};
