import { authApi } from '../slice/auth';
import { workspacesApi } from '../slice/workspaces';
import { issuesApi } from '../slice/issues';
import { repositoriesApi } from '../slice/repositories';
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

export const reducer = {
  [authApi.reducerPath]: authApi.reducer,
  [workspacesApi.reducerPath]: workspacesApi.reducer,
  [issuesApi.reducerPath]: issuesApi.reducer,
  [repositoriesApi.reducerPath]: repositoriesApi.reducer,
};

export const middleware = [
  authApi.middleware,
  workspacesApi.middleware,
  issuesApi.middleware,
  repositoriesApi.middleware,
];

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().prepend(...middleware),
});
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
