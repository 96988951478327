import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  components: {
    Tooltip: {
      baseStyle: {
        px: 4,
        py: 3,
        borderRadius: 'lg',
      },
    },
    Button: {
      defaultProps: {
        size: 'sm',
      },
      baseStyle: {
        borderRadius: 'md',
      },
    },
  },
});
