import React from 'react';
import { SideNav } from 'src/components/nav/side-nav';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';
import { Outlet } from 'react-router-dom';

export const DefaultLayout: React.FC = () => {
  const { isLoggedIn, isLoading, user } = useAuth();

  if (!isLoading && !isLoggedIn && !user?.lastUsedWorkspaceId)
    return <Navigate to={`/signup`} />;

  return (
    <div className="flex justify-center items-center h-full w-full">
      <div className="flex h-full w-full">
        <div className="flex h-full w-full m-auto">
          <div className="relative flex flex-row min-h-full min-w-full h-full rounded-xl">
            <div className="w-72 min-h-full relative">
              <SideNav />
            </div>
            <div className="flex-grow min-h-full p-3 overflow-hidden">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
