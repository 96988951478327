import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetRepositoriesQuery } from 'src/slice/repositories';

export function useRepositories() {
  const { workspaceId } = useParams();
  const [searchRepositoriesQuery, setSearchRepositoriesQuery] = useState('');

  const { data, isLoading, isFetching } = useGetRepositoriesQuery({
    workspaceId,
  });

  const repositories = data || [];
  const filteredRepositories =
    searchRepositoriesQuery === ''
      ? repositories
      : repositories.filter(repository => {
          return repository.fullName
            .toLowerCase()
            .includes(searchRepositoriesQuery.toLowerCase());
        });

  return {
    repositories: data || [],
    isLoadingRepositories: isLoading || isFetching,
    searchRepositoriesQuery,
    setSearchRepositoriesQuery,
    filteredRepositories,
  };
}
