import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Signup } from './routes/signup';
import { Home } from './routes/home';
import { Loading } from './routes/loading';
import { useAuth } from './hooks/useAuth';
import { Wildcard } from './routes/wildcard';
import { Issues } from './routes/issues';
import { DefaultLayout } from './routes/default-layout';
import { Setup } from './routes/setup';

export const App: React.FC = () => {
  const { isLoading } = useAuth();

  return (
    <div className="min-h-full bg-gray-50 h-full">
      {isLoading ? (
        <Loading />
      ) : (
        <Router>
          <Routes>
            <Route path="signup" element={<Signup />} />
            <Route path="/:workspaceId" element={<DefaultLayout />}>
              <Route path="setup" element={<Setup />} />
              <Route path="home" element={<Home />} />
              <Route path="issues" element={<Issues />} />
            </Route>
            <Route path="*" element={<Wildcard />} />
          </Routes>
        </Router>
      )}
    </div>
  );
};
