import { Skeleton as ChakraSkeleton } from '@chakra-ui/react';

export const Skeleton: React.FC = () => {
  return (
    <tr>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
        <ChakraSkeleton className="h-[25px] w-[450px]" />
      </td>
      <td className="max-w-[200px] whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <ChakraSkeleton className="h-[25px] w-[180px]" />
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        <ChakraSkeleton className="h-[25px] w-[80px]" />
      </td>
      <td>
        <ChakraSkeleton className="h-[25px] w-[100px]" />
      </td>
    </tr>
  );
};
