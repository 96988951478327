import { useParams } from 'react-router-dom';
import { useFixIssueMutation, useLazyGetIssuesQuery } from 'src/slice/issues';

export function useIssues() {
  const { workspaceId } = useParams();
  const [getIssues, { data, isLoading, isFetching }] = useLazyGetIssuesQuery();
  const [fixIssue, { isLoading: isFixingIssue }] = useFixIssueMutation();

  function fetchIssues(repository: string) {
    if (workspaceId && repository) getIssues({ workspaceId, repository });
  }

  function onFixIssue(issueId: number) {
    if (workspaceId) fixIssue({ workspaceId, issueId });
  }

  return {
    issues: data || [],
    isLoadingIssues: isLoading || isFetching,
    fetchIssues,
    fixIssue: onFixIssue,
    isFixingIssue,
  };
}
