import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/hooks/useAuth';

export const Wildcard: React.FC = () => {
  const { isLoggedIn, isLoading, user } = useAuth();

  if (!isLoading && isLoggedIn)
    return <Navigate to={`/${user.lastUsedWorkspaceId}/issues`} />;

  return <Navigate to={`/signup`} />;
};
