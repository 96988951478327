import { baseQuery } from '../initializers/rtk';
import { createApi } from '@reduxjs/toolkit/query/react';
import humps from 'humps';
import { Issue } from 'src/types/github/Issue';

export const issuesApi = createApi({
  baseQuery,
  reducerPath: 'issues',
  tagTypes: ['Issues'],
  endpoints: builder => ({
    getIssues: builder.query<
      Issue[],
      { workspaceId: string; repository: string }
    >({
      query: ({ workspaceId, repository }) => ({
        url: `/issues`,
        params: humps.decamelizeKeys({ workspaceId, repository }),
        withCredentials: true,
      }),
      providesTags: ['Issues'],
      transformResponse: (response: Issue[]): Issue[] =>
        humps.camelizeKeys(response) as Issue[],
    }),
    fixIssue: builder.mutation<
      undefined,
      { workspaceId: string | number; issueId: string | number }
    >({
      query: ({ workspaceId, issueId }) => {
        return {
          url: `/issues/${issueId}/fix`,
          method: 'POST',
          params: humps.decamelizeKeys({ workspaceId, issueId }),
          withCredentials: true,
        };
      },
      invalidatesTags: ['Issues'],
      transformResponse: response => Promise.resolve(undefined),
    }),
  }),
});

export const { useGetIssuesQuery, useLazyGetIssuesQuery, useFixIssueMutation } =
  issuesApi;
