import { Workspace } from 'src/types/Workspace';
import { baseQuery } from '../initializers/rtk';
import { createApi } from '@reduxjs/toolkit/query/react';
import humps from 'humps';

export const workspacesApi = createApi({
  baseQuery,
  reducerPath: 'workspaces',
  tagTypes: ['Workspaces'],
  endpoints: builder => ({
    getWorkspace: builder.query<Workspace, { workspaceId: string | number }>({
      query: ({ workspaceId }) => ({
        url: `/workspaces/${workspaceId}`,
        params: humps.decamelizeKeys({ workspaceId }),
        withCredentials: true,
      }),
      providesTags: ['Workspaces'],
      transformResponse: (response: Workspace): Workspace =>
        humps.camelizeKeys(response) as Workspace,
    }),
    updateWorkspace: builder.mutation<Workspace, any>({
      query: ({ workspaceId, workspace }) => {
        return {
          url: `/workspaces/${workspaceId}`,
          method: 'PUT',
          params: humps.decamelizeKeys({ ...workspace }),
          withCredentials: true,
        };
      },
      invalidatesTags: ['Workspaces'],
      transformResponse: (response: Workspace): Workspace =>
        humps.camelizeKeys(response) as Workspace,
    }),
    triggerError: builder.mutation<
      Workspace,
      { workspaceId: string | number; issue: string }
    >({
      query: ({ workspaceId, issue }) => {
        return {
          url: `/workspaces/${workspaceId}/trigger_sentry_issue`,
          method: 'PUT',
          params: humps.decamelizeKeys({ workspaceId, issue }),
          withCredentials: true,
        };
      },
      invalidatesTags: ['Workspaces'],
      transformResponse: (response: Workspace): Workspace =>
        humps.camelizeKeys(response) as Workspace,
    }),
  }),
});

export const {
  useGetWorkspaceQuery,
  useUpdateWorkspaceMutation,
  useTriggerErrorMutation,
} = workspacesApi;
