import { LockClosedIcon } from '@heroicons/react/20/solid';
import { Repository } from 'src/types/github/Repository';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import { cx } from 'src/utils/cx';

export const RepositoryMenu: React.FC<{
  selectedRepository: Repository;
  setSelectedRepository: (repository: Repository) => void;
  filteredRepositories: Repository[];
  setSearchRepositoriesQuery: (query: string) => void;
}> = ({
  selectedRepository,
  setSelectedRepository,
  filteredRepositories,
  setSearchRepositoriesQuery,
}) => {
  return (
    <Combobox
      as="div"
      value={selectedRepository}
      onChange={setSelectedRepository}
    >
      <div className="relative">
        <Combobox.Input
          className="w-full  outline-black ring-1  rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-gray-300 sm:text-sm sm:leading-6"
          onChange={event => setSearchRepositoriesQuery(event.target.value)}
          displayValue={(repository: Repository) => repository?.fullName}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredRepositories.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredRepositories.map(respository => (
              <Combobox.Option
                key={respository.id}
                value={respository}
                className={({ active }) =>
                  cx(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-gray-200' : 'text-gray-900',
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      <div className="w-[10px]">
                        {respository.private ? (
                          <LockClosedIcon className="h-3 w-3" />
                        ) : null}
                      </div>
                      <span
                        className={cx(
                          'ml-3 truncate',
                          selected && 'font-semibold',
                        )}
                      >
                        {respository.fullName}
                      </span>
                    </div>

                    {selected && (
                      <span
                        className={cx(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                        )}
                      >
                        <CheckIcon className="h-4 w-4" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};
