import { Button, Divider } from '@chakra-ui/react';
import * as Page from 'src/components/layout/page';
import * as SetupItem from 'src/components/setup/setup-item';
import { OpenAiApiKeyInput } from 'src/components/setup/open-ai-api-key-input';
import { useWorkspace } from 'src/hooks/useWorkspace';
import { useTriggerErrorMutation } from 'src/slice/workspaces';
import { useState } from 'react';

export const Setup: React.FC = () => {
  const { workspace } = useWorkspace();
  const [issue, setIssue] = useState('');
  const [triggerError] = useTriggerErrorMutation();

  return (
    <Page.Container>
      <Page.Header>
        <Page.Title>Setup</Page.Title>
      </Page.Header>
      <Page.Divider />
      <Page.Body className="px-10">
        <div className="mt-6">
          <SetupItem.Container>
            <SetupItem.Title>OpenAI</SetupItem.Title>
            <SetupItem.Action>
              <OpenAiApiKeyInput />
              <SetupItem.Description>
                Phluto uses ChatGPT 4 under the hood. Learn how to create an
                OpenAI API key.
              </SetupItem.Description>
            </SetupItem.Action>
          </SetupItem.Container>
          <Divider />
          <SetupItem.Container>
            <SetupItem.Title>Sentry</SetupItem.Title>
            <SetupItem.Action>
              <div>
                <Button
                  size="sm"
                  onClick={() =>
                    window.location.replace(
                      `https://sentry.io/sentry-apps/phluto/external-install/`,
                    )
                  }
                >
                  {workspace?.sentryInstallationId
                    ? `Connected to ${workspace.sentryOrgSlug}`
                    : 'Connect Sentry'}
                </Button>
              </div>
              <SetupItem.Description>
                Allow Phluto to have access to your Sentry exceptions and fix
                them.
              </SetupItem.Description>
            </SetupItem.Action>
          </SetupItem.Container>
          <Divider />
          <SetupItem.Container>
            <SetupItem.Title>Github</SetupItem.Title>
            <SetupItem.Action>
              <div>
                <Button
                  size="sm"
                  onClick={() =>
                    window.location.replace(
                      `https://github.com/apps/phluto/installations/new`,
                    )
                  }
                >
                  {!workspace?.githubInstallationId
                    ? 'Connect Github'
                    : 'Configure'}
                </Button>
              </div>
              <SetupItem.Description>
                Add which repositories Phluto should be able to access so that
                it can create PRs to fix the Sentry exceptions
              </SetupItem.Description>
            </SetupItem.Action>
          </SetupItem.Container>
        </div>
        <div className="mt-6 flex gap-2 items-center">
          <input
            className="border border-gray-300 rounded h-8 focus:border-gray-500 outline:border-gray-500 outline-none px-2"
            value={issue}
            onChange={e => setIssue(e.target.value)}
          />
          <Button
            size="sm"
            onClick={() => triggerError({ workspaceId: workspace.id, issue })}
          >
            Trigger Error
          </Button>
        </div>
      </Page.Body>
    </Page.Container>
  );
};
