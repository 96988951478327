import { User } from 'src/types/User';
import { baseQuery } from '../initializers/rtk';
import { createApi } from '@reduxjs/toolkit/query/react';
import humps from 'humps';

export const authApi = createApi({
  baseQuery,
  reducerPath: 'auth',
  tagTypes: ['Auth'],
  endpoints: builder => ({
    login: builder.query<User, any>({
      query: ({ workspaceId }) => ({
        url: '/login',
        params: humps.decamelizeKeys({ workspaceId }),
        withCredentials: true,
      }),
      providesTags: ['Auth'],
      transformResponse: (response: User): User =>
        humps.camelizeKeys(response) as User,
    }),
    logout: builder.query<any, any>({
      query: () => ({
        url: '/logout',
        params: {},
        withCredentials: true,
      }),
      providesTags: ['Auth'],
      transformResponse: (response: any): any => response,
    }),
  }),
});

export const { useLoginQuery, useLazyLoginQuery, useLazyLogoutQuery } = authApi;
