import { Spinner } from '@chakra-ui/react';

export const Loading: React.FC = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <Spinner
        thickness="3px"
        speed="0.5s"
        emptyColor="gray.200"
        color="gray.800"
        size="md"
      />
    </div>
  );
};
