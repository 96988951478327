import React from 'react';
import { GithubSignupButton } from 'src/components/signup/github-signup-button';

export const Signup: React.FC = () => {
  return (
    <div className="bg-blue-600 flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 relative">
      <div className="mx-auto max-w-[540px]">
        <h1 className="text-3xl font-bold text-center mt-2 text-white">
          Since the beginning of time, software engineers have spent hours
          fixing bugs...
        </h1>
        <div className="mt-10">
          <GithubSignupButton />
        </div>
        <div className="absolute bottom-5 left-0 right-0">
          <p className="text-center text-white w-full">
            Coming soon by <b>Phluto</b>
          </p>
        </div>
      </div>
    </div>
  );
};
