import { Divider } from '@chakra-ui/react';
import * as Page from 'src/components/layout/page';

export const Home: React.FC = () => {
  return (
    <Page.Container>
      <Page.Header>Home</Page.Header>
      <Page.Divider />
    </Page.Container>
  );
};
