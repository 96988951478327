import {
  ComponentDefaultProps,
  Divider as ChakraDivider,
} from '@chakra-ui/react';
import { cx } from 'src/utils/cx';

export const Container: React.FC<ComponentDefaultProps> = ({ children }) => {
  return (
    <div className="w-full min-h-full relative left-[0px] rounded-lg bg-white shadow-md h-full overflow-y-scroll">
      <div className="mx-auto">
        <div className="mx-auto">{children}</div>
      </div>
    </div>
  );
};

export const Title: React.FC<ComponentDefaultProps> = ({ children }) => {
  return (
    <h2 className="text-lg font-semibold leading-7 text-gray-900 sm:truncate">
      {children}
    </h2>
  );
};

export const Header: React.FC<ComponentDefaultProps> = ({ children }) => {
  return (
    <h2 className="flex flex-row items-center justify-between px-10 min-h-[70px]">
      {children}
    </h2>
  );
};

export const Body: React.FC<ComponentDefaultProps> = ({
  children,
  className = '',
}) => {
  return (
    <div className={cx('h-full overflow-y-scroll', className)}>{children}</div>
  );
};

export const Actions: React.FC<ComponentDefaultProps> = ({ children }) => {
  return <h2 className="gap-2">{children}</h2>;
};

export const Description: React.FC<ComponentDefaultProps> = ({ children }) => {
  return <h2 className="leading-7 text-gray-600 sm:truncate">{children}</h2>;
};

export const Divider: React.FC<ComponentDefaultProps> = ({
  children,
  ...props
}) => {
  return <ChakraDivider {...props} />;
};
